@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/font/SFProDisplay-Medium.woff2") format("woff2"),
    url("./assets/font/SFProDisplay-Medium.woff") format("woff");
  font-weight: medium;
  font-style: normal;
  font-display: swap;
}
/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}
*::-webkit-scrollbar-thumb {
  background-color: #fcd535;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.navbar-sticky--moved-up {
  background: #001133 !important;
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  left: 0;
  margin-top: -100px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}
.navbar-sticky--on {
  margin-top: 0;
}
.navbar-sticky--transitioned {
  -o-transition: 0.3s;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Inter";
  font-weight: 400;
  background-color: #ffffff;
  color: #1e1e1e;
  overflow-x: hidden;
  padding-right: 0px;
}
a {
  text-decoration: none;
}
.navbar-main.navbar {
  background: #fff;
}
.nav_middle .nav-link {
  font-weight: 400;
  font-size: 16px;
  color: #9aaccd !important;
}
.nav_middle .nav-link:hover,
.nav_middle .nav-link.active {
  color: #c2ff52 !important;
}
.nav-off .offcanvas-header {
  background-color: #1e2329 !important;
}
.navbar-toggler-icon {
  filter: invert(1);
}
.nav_middle {
  align-items: center;
  gap: 20px;
}
.nav-btn {
  display: flex;
  align-items: center;
  gap: 30px;
}
.primary-link {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
}
.primary-btn {
  border-radius: 8px;
  padding: 10px 24px;
  background: #fcd535;
  border: 1px solid #fcd535 !important;
  font-weight: 600;
  font-size: 16px;
  color: #1e2329;
  text-decoration: none;
  white-space: nowrap;
}
.primary-btn img {
  padding-left: 5px;
  filter: hue-rotate(18deg) saturate(100%) brightness(100%) contrast(85%);
}
.primary-btn:hover {
  background: #cda502;
}
.navbar-toggler:focus {
  box-shadow: none;
}
.navbar_end {
  align-items: center;
  gap: 10px;
}
.login_btn {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  padding: 10px 24px;
  background: transparent;
  border-radius: 8px;
  transition: 0.2s all;
}
.login_btn:hover {
  background: #fff;
  color: #001133;
}
.pos_rel {
  position: relative;
}
span.notification_count {
  position: absolute;
  background: #fcd535;
  color: #191d23;
  border-radius: 100px;
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
  font-weight: 600;
  top: -1px;
  right: -3px;
}
/* -----------------------------------------------------------banner-sec---------------------------------------------- */
.banner-sec {
  background: url(./assets/image/banner-bg.jpg) no-repeat center;
  background-size: cover;
  padding: 100px 0;
  overflow: hidden;
  z-index: 1;
  position: relative;
}
.banner-sec h1 {
  font-family: "SF Pro Display";
  font-weight: 500;
  font-size: 48px;
  line-height: 133%;
  color: #ffffff;
  margin-bottom: 0;
  padding-bottom: 36px;
}
.banner-sec p {
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  color: #ffffff;
  margin-bottom: 0;
  padding-bottom: 36px;
}
.secondary-link {
  font-weight: 500;
  font-size: 16px;
  text-decoration: underline !important;
  text-decoration-skip-ink: none;
  color: #fff;
  text-decoration: none;
  margin-left: 30px;
}
.secondary-link img {
  padding-left: 8px;
}
.happy-client h3 {
  font-weight: 500;
  font-size: 48px;
  color: #1764ff;
  margin-bottom: 0;
}
.happy-client p {
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  color: #fff;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-left: 20px;
}
.happy-client {
  display: flex;
  margin-top: 36px;
  align-items: center;
}
.ban-sec-img {
  position: relative;
}
.ban-sec-img::before {
  content: "";
  background: #3c22f3;
  filter: blur(150px);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  background-size: 100%;
  right: 0;
  top: 0;
  z-index: -1;
}
.ban-sec-img img {
  position: relative;
  z-index: 1;
}
/* ----------------------------------------banner-sec-2------------------------------------------ */
section {
  padding: 50px 0;
}
.banner-sec-2 {
  background-color: #151026;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.banner-sec-2::after {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: rgba(194, 255, 82, 0.8) !important;
  filter: blur(90px);
  width: 22%;
  height: 32%;
  top: 0;
  right: -146px;
  z-index: -1;
}
.banner-sec-2::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: rgba(194, 255, 82, 0.8) !important;
  filter: blur(90px);
  width: 22%;
  height: 32%;
  bottom: 0;
  left: -146px;
  z-index: -1;
}
.banner-sec-2 h2 {
  font-family: "SF Pro Display";
  font-weight: 500;
  font-size: 40px;
  line-height: 130%;
  color: #fff;
  margin-bottom: 0;
  padding-bottom: 36px;
  max-width: 80%;
}
.banner-sec-2 p {
  font-weight: 400;
  font-size: 18px;
  color: #fff;
  margin-bottom: 0;
  padding-bottom: 36px;
}
.banner-sec-2 .nav-pills .nav-link {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  margin-right: 15px;
}
.banner-sec-2 .nav-pills .nav-link.active,
.banner-sec-2 .nav-pills .show > .nav-link {
  border-radius: 100px;
  padding: 8px 16px;
  background: #c2ff52;
  color: #151026;
}
.ban2-tab-content {
  border: 1px solid rgba(23, 100, 255, 0.05);
  border-radius: 18px;
  padding: 32px;
  box-shadow: 0 2px 6px 2px rgba(23, 100, 255, 0.15), 0 1px 2px 0 rgba(23, 100, 255, 0.3);
  background: #fff;
}
.ban2-tab-content p {
  font-weight: 400;
  font-size: 20px;
  color: #16181d;
  font-family: "SF Pro Display";
  margin-bottom: 0;
  padding-bottom: 18px;
}
.ban2-tab-content p span {
  margin-right: 20px;
}
.ban2-tab-content h6 {
  font-weight: 500;
  font-size: 20px;
  color: #188038;
  margin-bottom: 0;
  padding-bottom: 18px;
}
.ban2-tab-content h6 span {
  margin-left: 5px;
}
.ban2-tab-content h3 {
  font-weight: 600;
  font-size: 32px;
  color: #16181d;
  margin-bottom: 0;
}
/* -------------------------------------------------banner-sec-3-------------------------------------------------------- */

.banner-sec-3 {
  background: #1c182a;
  overflow: hidden;
}
.platform-choose {
  display: flex;
  position: relative;
  z-index: 1;
  /* */
}
.platform-choose::after {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #3c22f3 !important;
  filter: blur(247px);
  width: 97%;
  height: 78%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  z-index: -1;
}
.head {
  font-weight: 500;
  font-size: 40px;
  color: #ffff;
  font-family: "SF Pro Display";
  margin-bottom: 50px;
}
.platform-choose .box {
  border: 1px solid #d7dfee;
  border-radius: 22px;
  padding: 32px;
  /* box-shadow: 5px 5px 0 0 #d7dfee; */
  background: #fff;
  min-height: 280px;
  margin-bottom: 24px;
  transition: 0.2s all;
  cursor: default;
  overflow: hidden;
}
.platform-choose .box:hover {
  background: url(./assets/image/box-hover.png);
  background-size: cover;
  background-repeat: no-repeat;
  border: 1px solid #c8d8ff;
  box-shadow: none;
}
/* .platform-choose .box:hover h4 {
  color: #1764ff;
} */
.platform-choose .box h4 {
  font-weight: 600;
  font-size: 24px;
  color: #1c182a;
  margin-bottom: 0;
  padding: 24px 0px;
}
.platform-choose .box p {
  font-weight: 400;
  font-size: 16px;
  line-height: 162%;
  color: #1c182a;
  margin-bottom: 0;
}
/* -----------------------------------------------banner-sec-4-------------------------------------------------- */
.banner-sec-4 {
  background: url(./assets/image/box-hover.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 70px 0px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.banner-sec-4 h2 {
  color: #1c182a;
}
.banner-sec-4 p {
  font-weight: 500;
  font-size: 16px;
  line-height: 162%;
  color: #1c182a;
  margin-bottom: 0;
  padding-bottom: 36px;
}
.white-btn {
  border-radius: 8px;
  padding: 16px 24px;
  background: #fff;
  font-weight: 600;
  font-size: 16px;
  color: #1764ff;
  text-decoration: none;
}
.white-btn img {
  padding-left: 8px;
}
.image-client {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}
/* --------------------------------------------banner-sec-5-------------------------------------------- */
.banner-sec-5 {
  position: relative;
  z-index: 1;
  padding: 150px 0;
  overflow: hidden;
  background: #1c182a;
}
.banner-sec-5::after {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #3c22f3 !important;
  filter: blur(144px);
  width: 38%;
  height: 63%;
  bottom: -263px;
  left: -147px;
  z-index: -1;
}
.banner-sec-5::before {
  content: "";
  background: url("./assets/image/sec3-ban.png") no-repeat right center;
  width: 870px;
  height: 857px;
  position: absolute;
  background-size: cover;
  top: -40px;
  right: -100px;
  z-index: -1;
}
.ratings {
  list-style-type: none;
  display: flex !important;
  padding-left: 0;
  margin-bottom: 0;
}
.ratings .ratings-star i {
  color: #d9d9d9 !important;
}
.rating-profile {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.rating-profile p {
  margin-bottom: 0;
  text-align: start;
  font-weight: 600;
  font-size: 18px;
  color: #fff;
}
.testimonial_nav_slider .slick-slide {
  background: transparent !important;
  border-radius: 100px;
  padding: 12px;
  cursor: pointer;
}
.testimonial_nav_slider .slick-current {
  background: #c2ff52 !important;
}
.testimonial_nav_slider .slick-current .rating-profile p {
  color: #1c182a;
}
.testimonial_nav_slider .slick-current .rating-profile .ratings .ratings-star i {
  color: #ff3d00 !important;
}
.slick-slider.testimonial_nav_slider {
  border: 1px solid #d7dfee;
  border-radius: 100px;
  padding: 6px;
}
.testimonial_nav_slider .slick-prev:before,
.testimonial_nav_slider .slick-next:before {
  color: #ff3d00;
}
.testimonial_main_slider h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  color: #fff;
}
.testimonial_main_slider {
  margin-top: 40px;
}
.testimonial_main_slider h4 span {
  font-style: italic;
  font-weight: 600;
  color: #c2ff52;
}
/* ----------------------------------------------------------banner-sec-6--------------------------------------------------- */
.banner-sec-6 {
  background: url(./assets/image/sec6-ban.png) no-repeat;
  background-size: cover;
  padding: 80px 0px;
}
.buy-crypto {
  text-align: center;
  margin: 30px 0px;
}
.buy-crypto h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 133%;
  text-align: center;
  color: #fff;
  font-family: "SF Pro Display";
}
.banner-sec-6-head {
  justify-content: end;
}
.white-orange-btn {
  background: #fff;
  border-radius: 8px;
  padding: 12px 24px;
  cursor: pointer;
}
.white-orange-btn p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 11px;
  color: rgba(0, 17, 51, 0.8);
  padding-bottom: 5px;
}
.white-orange-btn h6 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
  color: #ff3d00;
}
.white-orange-btn h6 span {
  padding-right: 8px;
}
.buy-crypto-btns {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 36px;
}
.buy-crypto-btns .primary-btn {
  padding: 22px 24px !important;
}
/* ---------------------------------------------banner-sec-7---------------------------------------- */
.banner-sec-7 {
  background: #1c182a;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.banner-sec-7::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #3c22f3 !important;
  filter: blur(144px);
  width: 38%;
  height: 63%;
  top: -263px;
  left: -147px;
  z-index: -1;
}
.banner-sec-7::after {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #3c22f3 !important;
  filter: blur(133px);
  width: 15%;
  height: 48%;
  top: 342px;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.banner-sec-7-acc {
  background: #151026 !important;
  border-radius: 18px;
}
.banner-sec-7-acc
  .accordion-item:last-of-type
  > .accordion-header
  .accordion-button.collapsed {
  border-radius: 0 0 18px 18px !important;
}
.banner-sec-7-acc .accordion-item .accordion-body:last-child {
  border-radius: 0 0 18px 18px !important;
}
.banner-sec-7-acc .accordion-item {
  background: #151026;
  border: 1px solid #2e2b3d;
}
.banner-sec-7-acc .accordion-item:first-child,
.banner-sec-7-acc .accordion-item:first-child .accordion-button {
  border-radius: 18px 18px 0 0;
}
.banner-sec-7-acc .accordion-item:last-child,
.banner-sec-7-acc .accordion-header:last-child {
  border-radius: 0 0 18px 18px !important;
}
.banner-sec-7-acc .accordion-item .accordion-button {
  border-bottom: none;
  box-shadow: none;
  font-weight: 600;
  background: #151026;
  font-size: 20px;
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
}
.banner-sec-7-acc .accordion-button:not(.collapsed) {
  background: #151026;
}
.banner-sec-7-acc .accordion-button:focus {
  box-shadow: none;
}
.banner-sec-7-acc .accordion-item .accordion-button::after {
  background: url("./assets/image/accordian_icon.png") no-repeat right;
  width: 14px;
  height: 9px;
}
.banner-sec-7-acc .accordion-item .accordion-body p {
  font-weight: 400;
  font-size: 18px;
  line-height: 144%;
  color: #fff;
  margin-bottom: 0;
}
.banner-sec-7-acc .accordion-item .accordion-body {
  padding-top: 0;
  background: #151026;
}
.accordian_head_count {
  border-radius: 100px;
  background: #c2ff52;
  padding: 5px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #1c182a;
  margin-right: 16px;
}
.banner-sec-8 {
  background: #1c182a;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.banner-sec-8::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #c2ff52 !important;
  filter: blur(144px);
  width: 38%;
  height: 63%;
  bottom: -263px;
  left: -147px;
  z-index: -1;
}
.banner-sec-8::after {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #3c22f3 !important;
  filter: blur(133px);
  width: 15%;
  height: 48%;
  top: -53px;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.news_card {
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background: linear-gradient(192deg, #fefffc 0%, #e4ffb3 100%);
  background-size: cover;
  border-radius: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition: 0.2s all;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  transition: transform 0.5s ease;
}
.news_card div {
  position: relative;
  overflow: hidden;
  padding: 10px;
  transition: all 0.7s ease-in-out;
}
.news_card:hover {
  background: #fff;
}
.news_card:hover div {
  transform: scale(1.08) !important;
  padding: 10px;
}
.news_card img {
  border-radius: 12px;
}
.news_card h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 133%;
  color: #1c182a;
  margin-bottom: 0;
}
.news_card p {
  font-weight: 400;
  font-size: 16px;
  line-height: 162%;
  color: #1c182a;
  margin-bottom: 0;
}
.news_card a {
  border: 1px solid #322a5b;
  background: transparent;
  border-radius: 8px;
  padding: 12px 24px;
  font-weight: 500;
  font-size: 16px;
  color: #322a5b;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 170px;
  transition: 0.2s all;
}
.news_card a:hover {
  background: #ff3d00;
  border: 1px solid #ff3d00;
  color: #fff;
}
.news_card a:hover path {
  stroke: #fff;
}
.landing_main {
  min-height: 100vh;
}
.footer_section {
  background: #1e2329;
  padding: 30px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.footer_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.quick_links {
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
  padding: 0;
  margin: 0;
}
.quick_links li {
  font-weight: 400;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
}
.quick_links li a {
  font-weight: 400;
  font-size: 16px;
  color: #fff;
  transition: 0.2s all;
  text-decoration: none;
}
.quick_links li a:hover {
  color: #fcd535;
}
.footer_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_bottom_left {
  display: flex;
  align-items: center;
  gap: 30px;
}
.footer_bottom_left p {
  font-weight: 400;
  font-size: 15px;
  line-height: 173%;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 0;
}
.footer_bottom_left p a {
  font-weight: 600;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
}
.footer_bottom_left ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 30px;
  list-style: none;
}
.footer_bottom_left ul li a {
  font-weight: 400;
  font-size: 15px;
  line-height: 173%;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.5);
}
.social_links {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
  gap: 30px;
}
.social_links li a {
  color: rgba(255, 255, 255, 0.5);
  transition: 0.2s all;
  font-size: 24px;
}
.social_links li a:hover {
  color: #fcd535;
}
.offcanvas-header .btn-close {
  box-shadow: none !important;
}
.dashpage_wrapper {
  display: flex;
}
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 60px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
  width: 300px;
  align-items: flex-start;
  border-right: 1px solid #2e2b3d;
  background: #1e2329;
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebar .offcanvas-header {
  background-color: #242038;
}
.close_end {
  display: none;
}
.main_inner_content {
  margin-left: 300px;
  width: calc(100% - 300px);
}
.dash_section {
  min-height: calc(100vh - 180px);
  padding: 36px;
  background: #fff;
  background-size: cover;
}
.dash_navbar {
  /* backdrop-filter: blur(50px); */
  background: #fff;
  border-bottom: 1px solid #eaecef;
  padding: 8px 24px;
}
.sidebar_navlist {
  width: 100%;
  padding: 24px;
}
.sidebar_navlist li a.nav-link {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 600;
  font-size: 15px;
  color: #9fa7b4;
  border-radius: 8px;
  padding: 12px;
  background: transparent;
  border: 1px solid transparent;
}
.sidebar_navlist li a.nav-link.active {
  background: #fcd535;
  border: 1px solid #fcd535;
  color: #000;
}
.sidebar_navlist li a.nav-link.active img {
  filter: invert(100%);
}
.sidebar_navlist ul.nav {
  gap: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 24px;
}
.sidebar_navlist ul.nav:last-child {
  border-bottom: none;
  margin-top: 12px;
}
.sidebar_heading {
  font-weight: 400;
  font-size: 14px;
  color: #707a8a;
  margin-bottom: 15px;
  margin-top: 24px;
}
.sidebar_navlist .sidebar_heading:nth-child(1) {
  margin-top: 0;
}
.inner_nav_right {
  display: flex;
}
.inner_nav_right {
  display: flex;
  align-items: center;
  gap: 15px;
}
.dash_title {
  font-weight: 600;
  font-size: 20px;
  color: #1e2329;
  margin-bottom: 20px;
}
/* Auth pages */
.auth_wrapper {
  background: #fff;
  border-top: 1px solid #dadfe8;
  position: relative;
  /* padding: 80px 0 40px 0; */
  z-index: 1;
  overflow: hidden;
  /* min-height: calc(100vh - 200px); */
}
.login_img {
  position: relative;
  z-index: 1;
}

.auth_bg {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 16px;
  backdrop-filter: blur(20px);
  max-width: 80%;

  background: #fff;
  /* background-size: cover; */
  border-radius: 16px;
  padding: 48px;
  position: relative;
  margin: 100px 0px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  overflow: hidden;
}
.copyright {
  margin-top: 70px;
}
.copyright p {
  margin-bottom: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #707a8a;
}
.logo_img {
  display: none;
}
/* .auth_bg::before {
  content: "";
  position: absolute;
  background: #f7931aad;
  border-radius: 50%;
  filter: blur(76px);
  width: 50%;
  height: 56%;
  z-index: -1;
  top: -66px;
  right: -74px;
}

.auth_bg::after {
  content: "";
  position: absolute;
  background: #c2ff52c7;
  border-radius: 50%;
  filter: blur(76px);
  width: 50%;
  height: 56%;
  z-index: -1;
  bottom: -116px;
  left: -78px;
} */

/* .auth_bg::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #c2ff52 !important;
  filter: blur(84px);
  width: 100%;
  height: 35%;
  bottom: 0 ;
  left: 38px;
  z-index: -1;
} */
/* .auth_wrapper::before {
  content: "";
  background: url("./assets/image/bg_auth.png") no-repeat center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  z-index: -1;
} */
/*
.auth_wrapper::after {
  content: "";
  background: url("./assets/image/auth_img_02.png") no-repeat right top;
  width: 150px;
  height: 150px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
} */
.auth_header_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.auth_header_flex img {
  display: block;
  cursor: pointer;
}
.auth_header_flex img.active {
  display: none;
}
.auth_header h2 {
  font-weight: 700;
  font-size: 24px;
  color: #1e2329;
  margin-bottom: 30px;
}
.auth_header_flex h2 {
  margin-bottom: 0;
}
.auth_tab {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.auth_tab button {
  font-weight: 500;
  font-size: 18px;
  line-height: 144%;
  text-transform: capitalize;
  color: #fff;
  border: none;
  background: transparent;
  position: relative;
}
.auth_tab button::before {
  transition: 0.2s all;
}
.auth_tab button.active::before {
  content: "";
  stroke-width: 3.5px;
  background: #c2ff52;
  border-radius: 100px;
  width: 28px;
  height: 3.5px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  margin: 0 auto;
}
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-1.8rem) translateX(0.15rem);
  font-weight: 500;
  font-size: 13px;
  color: #fff;
  background: #241e35;
  padding: 5px !important;
  height: auto !important;
  top: 16px;
}
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  display: none;
}
.auth_form .form-control {
  box-shadow: none !important;
  border: 1px solid transparent;
  background: #f1f2f4;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  color: #1e2329 !important;
}
.auth_form .form-control::placeholder {
  font-weight: 400;
  font-size: 16px;
  color: #1e2329 !important;
}
.auth_form .form-control:focus {
  border-color: #dadfe8;
}
.auth_form .form-control:active {
  border-color: #dadfe8;
}
.auth_form label {
  font-weight: 400;
  font-size: 16px;
  color: #717276;
}
.auth_form .form-check {
  display: flex;
  align-items: center;
  gap: 10px;
}
.auth_form .form-check label {
  font-weight: 400;
  font-size: 15px;
  color: #1e2329;
}
.auth_form .form-check label a {
  font-weight: 600;
  text-decoration: underline;
  color: #fcd535;
}
.auth_form .form-check-input[type="checkbox"] {
  border-radius: 100px;
  border: 1px solid #d7dfee;
  width: 20px;
  height: 20px;
  box-shadow: none !important;
  margin-top: -2px;
}
.auth_form .form-check-input[type="checkbox"]:checked {
  background-color: #c2ff52;
  border-color: #c2ff52;
  background-image: url(./assets/image/tick.png) !important;
}
.position_eye {
  position: relative;
}
.showpass_icon {
  color: #1e2329;
  position: absolute;
  top: 8px;
  right: 20px;
  cursor: pointer;
}
.auth_link {
  font-weight: 400;
  font-size: 16px;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  color: #1e2329;
}
.signup_link {
  margin-top: 30px;
  font-weight: 400;
  font-size: 15px;
  color: #1e2329;
}
.signup_link a {
  font-weight: 600;
  text-decoration: underline;
  color: #fcd535;
}
.scan_note {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
}
.scan_note p {
  font-weight: 400;
  font-size: 15px;
  color: #fff;
  margin-bottom: 0;
}
/* Dashboard pages */
.crypto_card {
  backdrop-filter: blur(56px);
  background: linear-gradient(
    176deg,
    rgba(248, 248, 248, 0.8) 0%,
    rgba(248, 248, 248, 0.8) 100%
  );
  border: 1px solid #fff;
  border-radius: 16px;
  padding: 24px;
  height: 100%;
  overflow: hidden;
}
.crypto_card_row [class*="col-"] {
  margin-bottom: 30px;
}
.crypto_card_top,
.crypto_card_bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.crypto_card_top {
  border-bottom: 1px solid #dedede;
  padding-bottom: 16px;
  margin-bottom: 16px;
}
.crypto_card_top_left {
  display: flex;
  align-items: center;
  gap: 12px;
}
.crypto_card_badge {
  background: rgba(255, 255, 255, 0.06);
  border-radius: 4px;
  padding: 6px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 5px;
  justify-content: center;
}

.crypto_card_badge h5 {
  font-weight: 500;
  font-size: 11px;
  text-transform: uppercase;
  margin-bottom: 0;
  color: #fff;
  text-align: right;
}
.text_green {
  color: #17b334 !important;
}
.text_red {
  color: #ff2238 !important;
}
.crypto_card_top_left span {
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
}
.crypto_card_top_right h6 {
  font-weight: 400;
  font-size: 12px;
  text-align: right;
  color: #fff;
  margin-bottom: 0;
}
.crypto_card_price {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.crypto_card_price h3 {
  background: #2b3139;
  border-radius: 8px;
  padding: 8px 12px;
  font-weight: 600;
  font-size: 11px;
  color: #fff;
  margin-bottom: 0;
  white-space: nowrap;
}
.crypto_card_price h3 span {
  font-weight: 400;
  font-size: 11px;
  text-transform: uppercase;
  color: #707a8a;
}
.table_dash_card1 {
  border: 1px solid #dadfe8 !important;
  border-radius: 16px !important;
}
.primary_table {
  margin-bottom: 0;
}
.primary_table thead th {
  background: #f1f2f4;
  font-weight: 600;
  font-size: 16px;
  color: #1c182a;
  padding: 16px 24px;
  white-space: nowrap;
}
.primary_table thead th:first-child {
  border-radius: 16px 0 0 0;
}
.primary_table thead th:last-child {
  border-radius: 0 16px 0 0;
}
.primary_table tbody td {
  font-weight: 600;
  font-size: 16px;
  color: #16181d;
  padding: 16px 24px;
  white-space: nowrap;
  vertical-align: middle;
  border-bottom: 1px solid #dadfe8;
}
.primary_table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 16px;
}
.primary_table tbody tr:last-child td:last-child {
  border-radius: 0 0 16px 0;
}
.primary_table tbody tr:last-child td {
  border-bottom: none !important;
}
.border_btn {
  font-weight: 600;
  font-size: 16px;
  color: #1e2329;
  background: #f1f2f4;
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  padding: 12px 18px;
  width: 150px;
}
.border_btn1 {
  color: #1e2329;
}
.border_btn:hover {
  color: #1e2329;
  background: #d1d7e34f;
  border: 1px solid #d1d7e3;
}
.primary_table tbody tr:hover td {
  background: #f7f7f7 !important;
}
.withdraw_card {
  background: url("./assets/image/card_bg.png") no-repeat center;
  border: 1px solid transparent;
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  text-align: center;
  background-size: cover;
  justify-content: center;
}
.withdraw_card h3 {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #1e2329;
}
.withdraw_card h4 {
  font-weight: 700;
  font-size: 24px;
  color: #1e2329;
}
.withdraw_card h4 span {
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  color: #1e2329;
}
.height_eq_div [class*="col-"] > div {
  height: 100%;
}
.height_eq_div [class*="col-"] {
  margin-bottom: 30px;
}
.nav_history .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background: #f1f2f4 !important;
  color: #1e2329 !important;
  font-weight: 600 !important;
}
.nav_history .nav-pills .nav-link {
  color: #1e2329 !important;
}
.nav_history .primary_table tbody td {
  font-weight: 600 !important;
  font-size: 15px !important;
  color: #1e2329 !important;
  background: #ffff !important;
}
.nav_history .primary_table.table {
  border: 1px solid transparent !important;
}
/* .nav_history .primary_table tbody {
  border: 1px solid #dadfe8 !important;
} */
.nav_history .primary_table tbody tr:hover {
  background: #fff !important;
}
/* ------------------------------------------color----------------------------------------------------- */
.blue_blur {
  position: relative;
}
/* .blue_blur::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #3c22f3 !important;
  filter: blur(84px);
  width: 100%;
  height: 35%;
  top: -48px;
  left: 38px;
  z-index: -1;
} */

.green-shade {
  position: relative;
}
/* .green-shade::after {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: rgba(194, 255, 82, 0.9) !important;
  filter: blur(71px);
  width: 100%;
  height: 100%;
  top: 53px;
  right: 53px;
  z-index: -1;
} */
.crypto_card {
  border: 1px solid #1e2329 !important;
  background: #1e2329;
  position: relative;
}
.crypto_card::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  background: url(./assets/image/Vector.png) no-repeat !important;
  width: 214px;
  height: 214px;
  bottom: 0;
  top: 0;

  right: 0px;
  z-index: -1;
}
.crypto_card .crypto_card_top {
  border-bottom: 1px solid #2b3139 !important ;
}

/* ---------------------------------------buy-crypto---------------------------------------- */
.Buy_crypto_sec {
  background: #ffff;
  border: 1px solid #dadfe8;
  padding: 32px 20px;
  border-radius: 18px;
  z-index: 1;
  overflow: hidden;
}
/* .Buy_crypto_sec::after {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #3c22f3 !important;
  filter: blur(84px);
  width: 41%;
  height: 30%;
  top: -3px;
  right: 0;
  z-index: -1;
} */
.Buy_crypto_sec {
  position: relative;
}
/* .Buy_crypto_sec::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #3c22f3 !important;
  filter: blur(86px);
  width: 40%;
  height: 42%;
  left: 0;
  z-index: -1;
  
  bottom: 0 !important;
} */
.Buy_crypto_sec p {
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 162%;
  color: #fff;
}

.border_box {
  background: #f1f2f4;
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  padding: 24px;
  height: 100%;
}
.border_box p {
  font-weight: 600;
  font-size: 16px;
  color: #1e2329;
  margin-bottom: 16px;
}
.border_box h6 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 16px;
  color: #1e2329;
}
.send_details {
  background: #fff;
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
.send_details .form-select {
  width: 50%;
  border: 1px solid transparent;
  background: #1c182a;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  padding: 0 !important;
}
.send_details .form-select:focus {
  box-shadow: none;
}
.send_details img {
  padding-left: 5px;
}
.input_end {
  margin-top: 40px;
}
.form-control {
  box-shadow: none !important;
}
.input_end .form-control {
  background: #fff;
  border-radius: 8px;
  padding: 12px 16px;
  border: 1px solid transparent;
  font-weight: 700;
  font-size: 16px;
  color: #1e2329;
  text-align: center;
}
.input_end .form-control::placeholder {
  font-weight: 700;
  font-size: 16px;
  color: #1e2329;
}
.img_border {
  border-radius: 50%;
  background: #403d4f !important;
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_border img {
  padding-left: 0 !important;
  width: 16px;
  height: 16px;
}
.border-none .form-control {
  border: 1px solid transparent;
  background: transparent;
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  color: #1e2329;
}
.border-none .form-control::placeholder {
  font-weight: 600;
  font-size: 14px;
  text-transform: capitalize;
  color: #9fa7b4;
}
.border-none .input-group-text {
  border: 1px solid transparent;
  background: transparent;
  padding: 0;
  padding-right: 10px;
}
.border-none {
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  background: #f1f2f4;
  padding: 16px;
}
.span_second .input-group-text {
  padding-right: 0 !important;
}
.span_second .form-control {
  padding-right: 10px;
}
.span_second .input-group-text a {
  background: #ff3d00;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 8px 12px;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
}
.span_second .input-group-text a:hover {
  background: #c42e00;
  border: 1px solid #ff3d00;
}
.span_second .input-group-text img {
  padding-left: 3px;
}
.span_second {
  padding: 8px;
}
.btn-center {
  text-align: center;
}
.lable-cls {
  font-weight: 600;
  font-size: 14px;
  color: #1e2329;
}
.Order_limit {
  border-radius: 18px;
  padding: 32px 24px;
  background: #f1f2f4;
}
.Order_limit p {
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
  color: #1e2329;
  margin-bottom: 0;
}
.nav_history .primary_table tbody td p.text_green {
  margin-bottom: 0;
}
.table_icon {
  padding-right: 10px;
}
.nav_history .primary_table tbody td:hover tr {
  background: #fff !important;
}
/* ----------------------------------------order-confrim-model---------------------------------- */

/* .order_confrim_model .modal-content::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: rgba(194, 255, 82, 0.35) !important;
  filter: blur(52px);
  width: 58%;
  height: 30%;
  top: -22px;
  right: -96px;
  z-index: -1;
}
.order_confrim_model .modal-content::after {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: rgba(194, 255, 82, 0.35) !important;
  filter: blur(79px);
  width: 52%;
  height: 39%;
  bottom: -28px;
  left: 0;
  z-index: -1;
} */
.order_confrim_model .modal-title {
  font-weight: 600;
  font-size: 24px;
  color: #1e2329;
}
.order_confrim_model .modal-header {
  border-bottom: 1px solid transparent;
}
.order_confrim_model .modal-content {
  background: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1;
}
.modal-content .btn-close {
  background: url(./assets/image/close-btn1.png) no-repeat !important;
}
.order_confrim_model .modal-body p {
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
  color: #1e2329;
}
.order_confrim_model label {
  font-weight: 600;
  font-size: 14px;
  color: #1e2329;
}
.order_confrim_model .form-control {
  font-weight: 600;
  font-size: 14px;
  color: #1e2329;
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  background: #f1f2f4;
}
.order_confrim_model .form-control::placeholder {
  font-weight: 600;
  font-size: 14px;
  color: #1e2329;
}
.order_confrim_model .modal-body button {
  border: 1px solid transparent;
  font-weight: 500;
  font-size: 16px;
  background: #fcd535;
  color: #1e2329 !important;
  text-decoration: none;
}
.order_confrim_model .modal-body button:hover {
  text-decoration: underline;
  background: transparent;
  text-decoration: none;
  color: #fcd535 !important;
  border: 1px solid transparent;
}
.order_confrim_model .modal-body button img {
  padding-left: 5px;
}
.order_confrim_model .modal-body button .white_arrow {
  display: inline;
}
.order_confrim_model .modal-body button:hover .orange_arrow {
  display: inline;
}
.order_confrim_model .modal-body button:hover .white_arrow {
  display: none;
}
.order_confrim_model .modal-body button .orange_arrow {
  display: none;
}

.model_btn {
  display: flex;
  gap: 25px;
  margin-bottom: 15px;
}
.btn-close {
  background: url(./assets/image/close-btn.png) no-repeat !important;
}
.btn-close:focus {
  box-shadow: none;
}
/* -----------------------------------------account--------------------------------- */
.com-border {
  border: 1px solid #dadfe8;
  border-radius: 16px;
  padding: 24px;
  background: #fff;
}
.account_head {
  display: flex;
  align-items: center;
  gap: 24px;
}
.account_head div h4:first-child {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  word-break: break-all;
}
.account_head div h5 {
  font-weight: 500;
  font-size: 15px;
  color: #c2ff52;
}
.account_head .btn_account {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.account_head .btn_account button:first-child {
  border: 1px solid #2e2b3d;
  border-radius: 8px;
  padding: 8px 16px;
  background: #1c182a;
  font-weight: 600;
  font-size: 13px;
  color: #d7dfee;
}
.account_head .btn_account button:nth-child(2) {
  border: 1px solid transparent;
  border-radius: 8px;
  padding: 8px 16px;
  background: #ff3d00;
  font-weight: 600;
  font-size: 13px;
  color: #d7dfee;
}
.account_profile {
  display: flex;
  align-items: center;
  gap: 36px;
  position: relative;
}
.account_profile2 {
  display: flex;
  justify-content: space-around;
}
.account_profile2 p {
  font-weight: 600;
  font-size: 14px;
  color: #d7dfee;
  margin-bottom: 10px;
  white-space: nowrap;
}
.account_profile2 h6 {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  margin-bottom: 0;
}
.account_profile button {
  border-radius: 8px;
  padding: 8px 12px;
  background: #fcd535;
  font-weight: 600;
  font-size: 13px;
  color: #1e2329;
  white-space: nowrap;
  border: 1px solid transparent;
}
.account_profile button:hover {
  background: transparent;
  border: 1px solid #fcd535;
}
.account_profile button:active {
  background: transparent !important;
  border: 1px solid #fcd535 !important;
}
.account_before {
  position: relative;
}
/* .account_before::after {
  content: "";
  position: absolute;
  background: #2e2b3d;
  width: 1px;
  height: 58%;
  top: 26px;
  right: -19px;
  margin-right: 10px;
} */
/* .account_profile::after {
  content: "";
  position: absolute;
  background: #2e2b3d;
  width: 1px;
  height: 58%;
  top: 26px;
  right: -19px;
  margin-right: 10px;
} */
.com-border.orange {
  background: #1e2329;
  position: relative;
  overflow: hidden;
}
.com-border.orange div {
  position: relative;
  z-index: 10;
}
.com-border.orange::after {
  content: "";
  position: absolute;
  background: url(./assets/image/Vector1.png) no-repeat;
  width: 337px;
  height: 147px;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.com-border.orange::before {
  content: "";
  position: absolute;
  background: url(./assets/image/Vector3.png) no-repeat;
  width: 843px;
  height: 147px;
  top: 0;
  right: 0;
  z-index: 1;
}
.account_profile p {
  font-weight: 600;
  font-size: 14px;
  color: #d7dfee;
  margin-bottom: 10px;
  white-space: nowrap;
}
.account_profile h6 {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  margin-bottom: 0;
}
.profile_account {
  display: flex;
  gap: 20px;
}
.account_detail {
  padding: 24px 0px;
  position: relative;
  z-index: 1;
}
.account_detail1 {
  position: relative;
  z-index: 1;
}
/* .account_detail1::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #f7931a !important;
  filter: blur(87px);
  width: 154%;
  height: 173%;
  bottom: -192px;
  left: 0;
  z-index: -1;
} */
/* .account_detail img {
  padding-right: 10px;
} */
.orange_blur {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
/* .orange_blur::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #f7931a !important;
  filter: blur(47px);
  width: 22%;
  height: 116%;
  top: -59px;
  right: -127px;
  z-index: -1;
} */
/* .orange_blur::after {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #f7931a !important;
  filter: blur(51px);
  width: 20%;
  height: 195%;
  bottom: -192px;
  left: -98px;
  z-index: -1;
} */
.email_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.email_head {
  display: flex;
  align-items: center;
  gap: 20px;
}
.email_head h6 {
  font-weight: 600;
  font-size: 18px;
  color: #1e2329;
}
.email_head p {
  font-weight: 600;
  font-size: 15px;
  color: #707a8a;
  margin-bottom: 0;
  white-space: nowrap;
}
.verify_img {
  padding-right: 10px;
}
/* -----------------------------------------blog_sec------------------------------------ */
.blog_sec p {
  font-weight: 400;
  font-size: 16px;
  line-height: 162%;
  color: #1e2329;
}
.blog_sec h6 {
  font-weight: 600;
  font-size: 20px;
  color: #1e2329;
}
/* ---------------------------------support-ticket------------------------- */
.support_tic_btngrp {
  margin-bottom: 25px;
  display: flex;
  align-items: center;
}

.support_tic_btngrp .btn-primary.active {
  border-radius: 8px;
  padding: 8px 16px;
  background: #f1f2f4;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  text-align: center;
  color: #1e2329;
  border: 1px solid transparent;
}
.support_tic_btngrp .btn-primary {
  border-radius: 8px;
  padding: 8px 16px;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  text-align: center;
  color: #1e2329;
  border: 1px solid transparent;
}
.support_tic_btngrp .btn-primary:active {
  border-radius: 8px;
  padding: 8px 16px;
  background: #f1f2f4;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  text-align: center;
  color: #1e2329;
  border: 1px solid transparent;
}
.primary_form label {
  font-weight: 600;
  font-size: 16px;
  color: #1e2329;
}
.primary_form .form-text {
  font-weight: 400;
  font-size: 14px;
  color: #707a8a;
}
.suppot-height {
  height: 100%;
  margin-bottom: 30px;
}
.ticket_panel .com-border h4 {
  font-weight: 500;
  font-size: 15px;
  color: #1e2329;
}
.ticket_panel .com-border.active {
  background: #f1f2f4;
  border: 1px solid #f1f2f4;
}
.ticket_panel_details {
  display: flex;
  justify-content: space-between;
}
.ticket_panel_details label {
  font-weight: 500;
  font-size: 13px;
  color: #1e2329;
}
.ticket_panel_details label span {
  font-weight: 600;
  color: #1e2329;
}
.ticket_details h4 {
  font-weight: 600;
  font-size: 16px;
  color: #1e2329;
  margin-bottom: 20px;
}
.ticket_details_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.ticket_details_flex label {
  font-weight: 500;
  font-size: 15px;
  color: #1e2329;
}
.ticket_details_flex label span {
  font-weight: 600;
  font-size: 15px;
  color: #1e2329;
}
.msg_box_div {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  justify-content: end;
}
.msg_box_div1 {
  justify-content: start !important;
}
.msg_box_content {
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  padding: 16px;
  background: #f1f2f4;
  font-weight: 600;
  font-size: 15px;
  line-height: 160%;
  /* color: #fff; */
}
.msg_box_panel {
  margin-bottom: 24px;
}
.msg_box_panel h6 {
  text-align: end;
  font-weight: 600;
  font-size: 13px;
  color: #707a8a;
  padding-right: 70px;
  margin-top: 6px;
}
.msg_box_panel.admin_msg_box_panel .msg_box_content {
  border: 1px solid #2e2b3d;
  border-radius: 0 10px 10px 10px;
  padding: 16px;
  background: #1c182a;
  color: white;
}
.msg_box_panel.admin_msg_box_panel h6 {
  text-align: start;
  font-weight: 600;
  font-size: 13px;
  color: #d7dfee;
  padding-left: 70px;
}
.support_txtbox_btngrp {
  position: relative;
}
.support_upload_btn {
  position: absolute;
}
.support_txtbox_btngrp {
  position: relative;
  display: flex;
  align-items: center;
}

.support_upload_btn {
  position: relative;
  display: inline-block;
  border: 1px solid transparent;
  background: transparent;
  padding: 0;
  height: auto;
  /* width: 100%;
  max-width: 52px; */
}

.support_image_button {
  position: relative;
  z-index: 1; /* Ensures the button is above the file input */
}
.support_txtbox {
  display: flex;
  border: 1px solid #f1f2f4;
  background: #f1f2f4;
  border-radius: 8px;
  padding: 8px;
}
.support_txtbox .form-control {
  padding: 0;
  height: auto;
  background: transparent;
  border: 1px solid transparent;
  font-weight: 400;
  font-size: 14px;
  color: #1e2329;
  width: 80%;
}
.support_txtbox .file_input.form-control {
  width: 80% !important;
}
.support_txtbox .form-control::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #1e2329;
}
.support_upload_btn:hover {
  background: transparent;
  border: 1px solid transparent;
}
.support_upload_btn:active {
  background: transparent !important;
  border: 1px solid transparent !important;
}
.input_style {
  border: 1px solid rgba(218, 223, 232, 0.87);
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #1e2329 !important;
}
.input_style::placeholder {
  font-weight: 500;
  font-size: 14px;
  color: #707a8a;
}
.input_style:focus {
  border: 1px solid rgba(218, 223, 232, 0.87) !important;
  background: #fff !important;
  box-shadow: none !important;
}
.support_upload_border {
  border: 1px solid #f1f2f4;
  background: #f1f2f4;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  white-space: nowrap;
  text-align: center;
}
.support_upload_border span {
  color: #1e2329;
  padding-left: 10px;
}
.support_upload_border:hover {
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  padding: 16px;
}
.attach {
  display: flex;
  flex-direction: column;
}
.support_empty {
  height: 100%;
  display: flex;

  flex-direction: column;
  justify-content: space-between;
}
.msg_box_wrapper {
  height: 420px;
  overflow-y: auto;
  padding-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.support_empty.text {
  font-weight: 500;
  font-size: 16px;
  color: #1e2329;
  line-break: anywhere;
}
.ticket_panel_bottom {
  display: flex;
  gap: 8px;
}
.suppot-height {
  z-index: 1;
  overflow: hidden;
  position: relative;
  min-height: 500px;
}
/* .suppot-height::before {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #3c22f3 !important;
  filter: blur(117px);
  width: 100%;
  height: 44%;
  top: -126px;
  right: -232px;
  z-index: -1;
} */
/* .suppot-height::after {
  content: "";
  position: absolute;
  border-radius: 100%;
  background-color: #3c22f3 !important;
  filter: blur(117px);
  width: 100%;
  height: 21%;
  bottom: 0;
  left: -232px;
  z-index: -1;
} */
.support_profile {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
}
.support_empty.suppot-height::after {
  display: none !important;
}
textarea {
  resize: none;
}
.form-select {
  appearance: auto !important;
}
.border_input {
  border: 1px solid transparent !important;
  background: transparent !important;
  padding: 0;
  padding-left: 10px !important;
}
.input_group {
  padding: 6px !important;
}
.border_input.input-group-text {
  font-weight: 600;
  font-size: 14px;
  color: #1e2329;
}
.input_group_model .input-group i {
  color: #1e2329 !important;
}
.input_group_model .form-control {
  background-color: transparent !important;
  border: 1px solid transparent !important;
  font-weight: 600;
  font-size: 14px;
  color: #1e2329;
}
.input_group_model .form-control::placeholder {
  color: #1e2329 !important;
}
.input_group_model .input-group-text {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}
.input_group_model .input-group {
  border: 1px solid #f1f2f4;
  border-radius: 8px;
  background: #f1f2f4;
}
.input_group_model .input-group-text span {
  color: #ff3d00;
}
.form-check-input:focus {
  box-shadow: none;
  border-color: #ff3d00 !important;
}
.form-check-input:checked {
  background-color: #fcd535 !important;
  border-color: #fcd535 !important;
  box-shadow: none;
}
.close_support {
  margin-left: auto;
}
.notification_div {
  display: flex;
  align-items: center;
  gap: 25px;
  justify-content: space-between;
  border-bottom: 1px solid #bebcc9;
  padding: 20px 0;
}

.notification_div p {
  font-weight: 500;
  font-size: 12px;
  color: #1e2329;
  margin-bottom: 0;
  white-space: nowrap;
}
.notification_div > div h6 {
  font-weight: 500;
  line-height: 177%;
  color: #1e2329;
  font-size: 13px;
  margin-bottom: 0;
  word-break: break-all;
}
.notification_div > div h5 {
  line-height: 177%;
  color: #fff;
  font-size: 13px;
  margin-bottom: 0;
  font-weight: 600;
}
.notification_div > div > div {
  margin-left: 15px;
}
.notify_delete {
  background: #f1f2f4;
  padding: 6px 10px;
  border-radius: 5px;
}
span.notify_active {
  background: #c2ff52;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  border: 2px solid #fff;
  position: absolute;
  left: 35px;
  top: 0;
}
span.notify_deactive {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  border: 2px solid #fff;
  position: absolute;
  left: 35px;
  top: 0;
  background: #b8b8b8;
}
.text-red {
  color: red;
  font-size: 16px;
}
.text-green {
  color: #17b334;
  font-size: 16px;
}
.link-button {
  color: #ff3d00;
}
.two_factor p {
  color: #1e2329;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.two_factor h5 {
  color: #1e2329;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.two_factor h6 {
  color: #1e2329;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 10px;
}
.notify_sub {
  display: flex;
  align-items: center;
  gap: 15px;
}
.no-content {
  color: black;
  text-align: center;
}
.dashboard_chart {
  max-width: 40%;
}
.pagination {
  background: transparent !important;
  margin-top: 20px;
}
.page-item {
  background: transparent !important;
  margin: 5px;
  border-radius: 5px !important;
}
.page-item:last-child .page-link {
  border-radius: 5px !important;
}
.page-item:first-child .page-link {
  border-radius: 5px;
}
.page-link {
  background: transparent;
  border-radius: 5px;
  border: 1px solid #fcd535;
  color: #1e2329 !important;
}
.active > .page-link,
.page-link.active {
  background: #fcd535 !important;
  border: 1px solid transparent;
  color: #1e2329 !important;
}
.page-link:hover {
  background: #fcd535 !important;
  border: 1px solid transparent;
  color: #1e2329 !important;
}
.page-link:focus {
  box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid transparent;
  -webkit-text-fill-color: #1e2329;
  -webkit-box-shadow: 0 0 0px 1000px #1b1b1c inset;
  transition: background-color 5000s ease-in-out 0s;
}

.swap_box {
  background-color: #2a3139;
  border-radius: 10px;
  font-size: 14px;
  margin: 0 0 0 auto;
  padding: 20px 25px 50px;
  width: 100%;
  height: fit-content;
  z-index: 1;
}
.heading_block {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.heading_block span {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
}
.heading_block i {
  color: #838c9b;
  font-size: 20px;
  transform: rotate(60deg);
  cursor: pointer;
  transition: 0.2s all;
}
.heading_block i.bi.bi-x {
  font-size: 26px;
}
.heading_block i + i {
  margin-left: 20px;
}
.swap_container {
  /* padding: 15px 0 25px; */
  position: relative;
  pointer-events: auto;
}
.swap_arrow_container {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background-color: #2a3139;
  box-shadow: inset 0 10px 20px rgba(0, 0, 0, 0.04), inset 0 2px 6px rgba(0, 0, 0, 0.04),
    inset 0 0 1px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  height: 24px;
  left: 50%;
  bottom: 88px;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 24px;
  z-index: 2;
}
.swap_arrow {
  background-color: #2a3139;
  width: 18px;
  height: 18px;
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
}
.swap_arrow i {
  color: #dddedf;
  font-size: 24px;
}
.swap_select_token_wrap {
  background: #ffff;
  border: 1px solid #f1f2f4;
  border-radius: 8px;

  box-sizing: border-box;
  position: relative;
  transition: 0.1s linear;
  height: 51px;
  display: flex;
  align-items: center;
  width: 86%;
}
.swap_select_token_wrap:nth-of-type(2n) {
  margin-bottom: 3px;
}
.swap_select_token_inner {
  font-weight: 600;
  margin: 15px;
  width: 100%;
}
.swap_select_token_inner > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.swap_select_token_inner > div + div {
  margin-top: 5px;
}
.swap_select_token {
  align-items: center;
  cursor: pointer;
  display: flex;
  border-radius: 8px;
  overflow: hidden;
}
.swap_select_token_option {
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  width: 100%;
}
/* .swap_select_token_option img {
  width: 25px;
  margin-right: 5px;
} */
.swap_token_title {
  max-width: 74px !important;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: block;
  color: #1e2329;
  font-weight: 700;
  font-size: 16px;
  padding-left: 10px;
}
.swap_select_token::after {
  content: url("./assets/image/down_arrow.png");
  margin-left: 10px;
  transform: rotate(0deg);
  transition: 0.2s linear;
  position: relative;
  top: 3px;
}
.swap_select_token_open::after {
  transform: rotate(180deg);
  top: -2px;
}
.swap_token_bal {
  color: #ababab;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}
.swap_token_input {
  border: 0;
  display: flex;
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-align: right;
  padding: 0 !important;
  width: 50%;
  background-color: transparent;
}
.swap_token_input:focus-visible {
  outline: none;
}
.token_search_container {
  background-color: inherit;
  border-radius: 0 0 6px 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
  -webkit-clip-path: inset(0 -20px -20px -20px);
  clip-path: inset(0 -20px -20px -20px);
  display: flex;
  flex-direction: column;
  height: 0;
  left: -1px;
  overflow: hidden;
  padding: 0 15px;
  position: absolute;
  top: 90%;
  transition: 0.2s linear;
  width: calc(100% + 2px);
  z-index: 3;
}
.token_search_container_open {
  height: 110px;
  padding: 10px 15px 40px;
}
.token_search_inp {
  background-color: #2a3139;
  color: #c4cbd3;
  background-image: url("./assets/image/btc.png");
  background-repeat: no-repeat;
  background-position: 10px;
  border-radius: 6px;
  border: 0;
  height: 40px;
  width: 100%;
  padding: 5px 5px 5px 36px;
  outline: none;
}
.token_search_list {
  background-color: inherit;
  border-radius: 0 0 6px 6px;
  box-sizing: border-box;
  -webkit-clip-path: inset(0 -20px -20px -20px);
  clip-path: inset(0 -20px -20px -20px);
  display: flex;
  flex-direction: column;
  height: 91%;
  left: -10px;
  overflow: auto;
  padding: 0 15px 15px 20px;
  position: absolute;
  top: 0;
  transition: 0.2s linear;
  width: calc(100% + 2px);
  z-index: 3;
}
.token_search_list_row {
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  width: 100%;
  padding: 5px 0;
  border-radius: 5px;
}
.token_search_list_row:hover {
  background-color: #c6ccd157;
}
span.token_search_list_row_img {
  margin-left: 5px;
}
.token_search_list_row_img img {
  width: 24px;
}
.token_search_list_cur_name {
  margin-left: 10px;
  color: #1e2329;
}
.token_search_list_coin_name {
  max-width: 125px;
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin: 0 5px 0 auto;
  color: #1e2329;
}
.swap_info > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.swap_info > div span {
  font-size: 16px;
  color: #80828a;
}
.swap_info_accordion {
  margin-bottom: 30px;
}
.swap_info_accordion .accordion-item,
.swap_info_accordion button {
  background: transparent !important;
  border: none;
  box-shadow: none !important;
}
.swap_info_accordion button {
  justify-content: center;
  padding: 0;
}
.accordion-button::after {
  margin-left: unset;
  filter: invert(0.5);
  background-size: 18px;
}
.swap_info_accordion .accordion-body {
  padding: 0;
}
.swap_info_accordion h2.accordion-header {
  position: relative;
}
.swap_info_accordion .swap_info > div:last-child {
  margin-bottom: 0;
}
.swap_info_accordion h2.accordion-header::before {
  content: "";
  background: #5e636b;
  width: 45%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 9px;
}
.swap_info_accordion h2.accordion-header::after {
  content: "";
  background: #5e636b;
  width: 45%;
  height: 1px;
  position: absolute;
  right: 0;
  top: 9px;
}
.select_crypto .form-select {
  box-shadow: none !important;
  background: #2e2b3d;
  background-image: none !important;
  border: 1px solid #2e2b3d !important;
  border-radius: 8px !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.04), 0 2px 6px rgba(0, 0, 0, 0.04),
    0 0 1px rgba(0, 0, 0, 0.04) !important;
  color: #fff;
  padding: 10px !important;
  display: flex;
  justify-content: center;
}
option {
  position: relative;
  left: 10px;
}
/* Media Queries */
@media screen and (max-width: 1500px) {
  /* .orange_blur::after {
    content: "";
    position: absolute;
    border-radius: 100%;
    background-color: #f7931a !important;
    filter: blur(51px);
    width: 24%;
    height: 190%;
    top: -390px;
    left: -98px;
    z-index: -1;
  }
  .orange_blur::before {
    content: "";
    position: absolute;
    border-radius: 100%;
    background-color: #f7931a !important;
    filter: blur(47px);
    width: 22%;
    height: 116%;
    top: -163px;
    right: -93px;
    z-index: -1;
  } */
  .account_profile {
    flex-wrap: wrap;
    justify-content: center;
  }
  /* .account_detail1::before {
    content: "";
    position: absolute;
    border-radius: 100%;
    background-color: #f7931a !important;
    filter: blur(87px);
    width: 154%;
    height: 173%;
    bottom: -192px;
    left: 100px;
    z-index: -1;
  } */
  /* .account_detail1 {
    width: 300px;
  } */
  .account_head {
    width: 300px;
  }
  .account_head .btn_account {
    flex-direction: row !important;
  }
  .account_profile .account_head.account_before::after {
    display: none;
  }
  .account_before::after {
    display: none;
  }
}
@media screen and (max-width: 1399px) {
  .crypto_card_price {
    width: 40%;
  }
  .crypto_card {
    padding: 12px;
  }
  .crypto_card_top {
    padding-bottom: 9px;
    margin-bottom: 9px;
  }
  .account_profile2 {
    margin-top: 20px;
  }
  .com-border.orange::after {
    display: none;
  }
  .com-border.orange::before {
    display: none;
  }
  .auth_bg {
    padding: 32px 40px;
    margin: 45px 0px;
    max-width: 90%;
  }
  .logpage {
    min-height: 100vh;
  }
}
@media screen and (max-width: 1199px) {
  .copyright {
    margin-top: 30px;
  }
  .offcanvas {
    background: #1e2329;
  }
  .close_end {
    display: inline;
    text-align: end !important;
  }
  .nav-off .offcanvas-body {
    background-color: #1e2329 !important;
  }
  .nav_middle {
    gap: 10px;
    margin-bottom: 10px;
  }
  .navbar_end {
    gap: 15px;
  }
  .banner-sec-5 {
    text-align: center;
  }
  .footer_bottom {
    flex-direction: column;
    gap: 12px;
  }
  .banner-sec h1 {
    font-size: 40px;
  }
  .footer_bottom_left p {
    text-align: center;
  }
  .sidebar {
    width: auto;
    z-index: 1100;
  }
  .main_inner_content {
    margin-left: 0;
    width: 100%;
  }
  .email_detail {
    flex-direction: column;
    gap: 20px;
  }
  .crypto_card_price {
    width: auto;
  }
  .crypto_card {
    padding: 18px;
  }
}
@media screen and (max-width: 991px) {
  .logpage {
    min-height: auto;
  }
  .auth_bg {
    padding: 32px 22px;
    margin: 0px 0px;
    max-width: 100%;
  }
  .head {
    font-size: 32px;
  }
  .buy-crypto h2 {
    font-size: 40px;
  }
  .banner-sec {
    text-align: center;
  }
  .happy-client {
    justify-content: center;
  }
  .banner-sec::before {
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  .banner-sec-2 .nav-pills .nav-link {
    margin-top: 30px;
  }
  .banner-sec-2,
  .banner-sec-4 {
    text-align: center;
  }
  .banner-sec-2 h2 {
    max-width: 100%;
  }
  .banner-sec-2 ul.nav.nav-pills {
    justify-content: center;
  }
  .platform-choose .box {
    text-align: center;
  }
  .banner-sec-4-head {
    margin-bottom: 50px;
  }
  .banner-sec-7-acc .accordion-item .accordion-body p,
  .banner-sec-7-acc .accordion-item .accordion-button {
    font-size: 16px;
  }
  .border_box {
    margin-bottom: 20px;
  }
  .notification_div {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .auth_wrapper::before {
    background: url(./assets/image/responsive-bg.png) no-repeat;
  }
  .auth_bg::before {
    display: none;
  }
  .auth_bg::after {
    display: none;
  }

  .swap_select_token_wrap {
    width: 100%;
  }
  .border_box_head {
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 767px) {
  .logpage {
    display: none;
  }
  .logo_img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .signup_link {
    text-align: center;
  }
  .footer_top,
  .footer_bottom_left {
    flex-direction: column;
    gap: 12px;
  }
  .buy-crypto h2 {
    font-size: 32px;
  }
  .buy-crypto-btns {
    flex-wrap: wrap;
  }
  .dash_section {
    padding: 15px;
  }
  .account_detail {
    padding: 0 !important;
  }
  .profile_account {
    flex-wrap: wrap;
    justify-content: center;
  }
  /* .account_detail1 {
    width: 200px;
  } */
  .account_profile2 p {
    font-size: 13px;
  }
  .account_profile2 h6 {
    font-size: 12px;
  }
}
@media screen and (max-width: 575px) {
  .copyright p {
    font-size: 13px;
  }
  .quick_links {
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 8px;
  }
  /* .account_head {
    flex-direction: column;
  } */
  .account_head {
    margin-bottom: 20px;
  }
  .profile_account {
    flex-direction: column !important;
  }
  .account_before::after {
    content: "";
    position: absolute;
    background: #2e2b3d;
    width: 1px;
    height: 176%;
    transform: rotate(90deg);
    top: 62px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
  .account_detail {
    text-align: center;
  }
  .account_detail1::before {
    display: none;
  }
  .email_head {
    flex-direction: column !important;
    justify-content: center !important;
  }
  .email_head h6 {
    text-align: center;
  }
  .email_head p {
    text-align: center;
  }
  span.notify_active {
    top: 11px;
  }
  span.notify_deactive {
    top: 11px;
  }
  .account_profile2 {
    flex-direction: column;
    gap: 24px;
    justify-content: center !important;
  }
  .account_profile2 h6 {
    font-size: 16px !important;
  }
  .account_profile2 p {
    font-size: 14px !important;
  }
}
